
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppSidebar from '@/components/global/sidebar/AppSidebar.vue';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import Client from '@/models/Client';
import ClientViewSidebarGeneralInfo from '@/components/global/sidebar/ClientViewSidebarGeneralInfo.vue';
import ClientViewSidebarTags from '@/components/global/sidebar/ClientViewSidebarTags.vue';
import ClientViewSidebarDefaultCommercialist from '@/components/global/sidebar/ClientViewSidebarCommercialist.vue';
import ClientViewSidebarOfficeUser from '@/components/global/sidebar/ClientViewSidebarOfficeUser.vue';
import ClientViewSidebarCustomerNotes from '@/components/global/sidebar/ClientViewSidebarCustomerNotes.vue';
import ClientViewSidebarAttachments from '@/components/global/sidebar/ClientViewSidebarAttachment.vue';
import ClientViewSidebarHistory from '@/components/global/sidebar/ClientViewSidebarHistory.vue';
import ClientViewSidebarCreationSource from '@/components/global/sidebar/ClientViewSidebarCreationSource.vue';

@Component({
    name: 'ClientViewSidebar',
    components: {
        AppSidebar,
        SidebarCard,
        ClientViewSidebarGeneralInfo,
        ClientViewSidebarTags,
        ClientViewSidebarDefaultCommercialist,
        ClientViewSidebarCustomerNotes,
        ClientViewSidebarAttachments,
        ClientViewSidebarHistory,
        ClientViewSidebarOfficeUser,
        ClientViewSidebarCreationSource,
    },
})
export default class ClientViewSidebar extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private client!: Client;
    @Prop({ required: true }) private value!: any;

    private loading = false;

    private get generalInfo() {
        if (this.client) {
            return {
                id: this.client.id,
                ingeniousId: this.client.visibleId,
                isRoltekPartner: this.client.roltekAppPartner,
                isRoltek: this.isRoltek,
            };
        }
    }

    private get isRoltek(): boolean {
        return this.$store.getters['jwtData/isRoltek']
    }

    private get isClientBadPayer(): boolean {
        if (!this.client || !this.isRoltek) {
            return false;
        }

        return this.client.clientTypes.some((type) => type.code === 'SLAB-PLAČNIK');
    }

    @Watch('client', {
        immediate: true,
    })
    private onClientChange() {
        if (!this.client) {
            this.loading = true;
        }
        if (this.client) {
            this.loading = false;
        }
    }
}
